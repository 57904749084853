// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { Children } from 'react';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  loans: getIcon('ic_analytics'),
  report: getIcon('ic_report'),
  reports: getIcon('ic_blog'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Займы',
    items: [
      {
        title: 'Список займов',
        path: PATH_DASHBOARD.general.loans,
        icon: ICONS.loans,

        children: [
          { title: 'Мои графики', path: PATH_DASHBOARD.general.loans },
          // { title: 'Доступные', path: '' },
        ],
      },
      // {
      //   title: 'Отчеты',
      //   path: '',
      //   icon: ICONS.report,

      //   children: [
      //     { title: 'Проводки', path: '' },
      //     { title: 'Раскрытия', path: '' },
      //   ],
      // },
    ],
  },
];

export default navConfig;
