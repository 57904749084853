// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CABINET = '/cabinet';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_CABINET = {
  root:ROOTS_CABINET ,
  user: {
    root: path(ROOTS_CABINET, '/user'),
    profile: path(ROOTS_CABINET, '/user/profile'),
    settings: path(ROOTS_CABINET, '/user/settings'),
    cards: path(ROOTS_CABINET, '/user/cards'),
    list: path(ROOTS_CABINET, '/user/list'),
    newUser: path(ROOTS_CABINET, '/user/new'),
    editById: path(ROOTS_CABINET, `/user/reece-chung/edit`),
    account: path(ROOTS_CABINET, '/user/account')
  },
  employee: {
    root: path(ROOTS_CABINET, '/employee'),
    profile: path(ROOTS_CABINET, '/employee/profile'),
    cards: path(ROOTS_CABINET, '/employee/cards'),
    list: path(ROOTS_CABINET, '/employee/list'),
    newUser: path(ROOTS_CABINET, '/employee/new'),
    editById: path(ROOTS_CABINET, `/employee/reece-chung/edit`),
    account: path(ROOTS_CABINET, '/employee/account')
  },
  company: {
    root: path(ROOTS_CABINET, '/company'),
    profile: path(ROOTS_CABINET, '/company/profile'),
    cards: path(ROOTS_CABINET, '/company/cards'),
    list: path(ROOTS_CABINET, '/company/list'),
    newEmployee: path(ROOTS_CABINET, '/company/employee-edit'),
    editById: path(ROOTS_CABINET, `/company/edit`),
    account: path(ROOTS_CABINET, '/company/account'),
    viewEmployeeProfile: path(ROOTS_CABINET, '/company/view-employee-profile'),
  },
}

export const PATH_PAGE = {
  home: "/",
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    loans: path(ROOTS_DASHBOARD, '/loans'),
    loanNew: path(ROOTS_DASHBOARD, '/loan/new'),
    loanInfo: path(ROOTS_DASHBOARD, '/loan-details/'),
    loanEar: path(ROOTS_DASHBOARD, '/loan-ear'),
    loanBuInfo : path(ROOTS_DASHBOARD , '/loan-bu-info'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  loanBU : {
    loanBUInfo : path(ROOTS_DASHBOARD , '/loan-bu-info'),
    paymentHistory : path(ROOTS_DASHBOARD , '/loan-bu-payments-history'),
    accounting : path(ROOTS_DASHBOARD , '/accounting'),
    accountingSelect : path(ROOTS_DASHBOARD , '/accounting-select'),
    accountingEdit : path(ROOTS_DASHBOARD , '/accounting-edit'),
    accountingAddDate : path(ROOTS_DASHBOARD , '/accounting-add-date'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    profile: path(ROOTS_DASHBOARD, '/company/profile'),
    cards: path(ROOTS_DASHBOARD, '/company/cards'),
    list: path(ROOTS_DASHBOARD, '/company/list'),
    newUser: path(ROOTS_DASHBOARD, '/company/new'),
    editById: path(ROOTS_DASHBOARD, `/company/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/company/account'),
    viewEmployeeProfile: path(ROOTS_DASHBOARD, '/company/view-employee-profile')
  },
  employee: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

