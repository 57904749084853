import React, { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody/TableBody';
import TextField from '@mui/material/TextField/TextField';
import { useTheme } from '@mui/material/styles';
import tableHeadClasses from '@mui/material/TableHead/tableHeadClasses';
import LoanBus from 'src/models/loan';
import { gridVisibleSortedTopLevelRowEntriesSelector } from '@mui/x-data-grid';
import moment from 'moment';

import { Box, Grid, Container, Card, List, ListItem, Button, Snackbar } from '@mui/material';

let tableHead = [
  {
    type: 'graphic',
    heading: [
      { code: 'date', value: 'Дата' },
      {
        code: '',
        value: 'Денежные потоки',
        child: [
          { code: '', value: 'Получение' },
          { code: '', value: 'Погашение' },
        ],
      },
      {
        code: '',
        value: 'Основной долг',
        child: [
          { code: '', value: 'Погашение' },
          { code: '', value: 'Остаток' },
        ],
      },
      {
        code: '',
        value: 'Вознаграждения',
        child: [
          { code: '', value: 'Начисление' },
          { code: '', value: 'Погашение' },
          { code: '', value: 'Остаток' },
        ],
      },
      { code: 'totalBalance', value: 'Общий остаток' },
    ],
  },
];

let testObject = {
  grafic: [
    {
      date: '2022-12-15T00:00:00.000Z',
      receiptAmount: 79898000,
      monthAmountPayment: 0,
      monthMainDebtRepayment: 0,
      monthMainDebtBalance: 79898000,
      monthCompensationAccural: 0,
      monthRepaymentAccural: 0,
      monthCompensationAccuralLeft: 0,
      totalBalance: 79898000,
    },
    {
      date: '2023-01-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3398900.76,
      monthMainDebtBalance: 76499099.24,
      monthCompensationAccural: 1331633.33,
      monthRepaymentAccural: 1331633.33,
      monthCompensationAccuralLeft: 0,
      totalBalance: 76499099.24,
    },
    {
      date: '2023-02-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3455549.1,
      monthMainDebtBalance: 73043550.14,
      monthCompensationAccural: 1274984.99,
      monthRepaymentAccural: 1274984.99,
      monthCompensationAccuralLeft: 0,
      totalBalance: 73043550.14,
    },
    {
      date: '2023-03-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3513141.59,
      monthMainDebtBalance: 69530408.55,
      monthCompensationAccural: 1217392.5,
      monthRepaymentAccural: 1217392.5,
      monthCompensationAccuralLeft: 0,
      totalBalance: 69530408.55,
    },
    {
      date: '2023-04-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3571693.95,
      monthMainDebtBalance: 65958714.6,
      monthCompensationAccural: 1158840.14,
      monthRepaymentAccural: 1158840.14,
      monthCompensationAccuralLeft: 0,
      totalBalance: 65958714.6,
    },
    {
      date: '2023-05-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3631222.18,
      monthMainDebtBalance: 62327492.42,
      monthCompensationAccural: 1099311.91,
      monthRepaymentAccural: 1099311.91,
      monthCompensationAccuralLeft: 0,
      totalBalance: 62327492.42,
    },
    {
      date: '2023-06-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3691742.55,
      monthMainDebtBalance: 58635749.87,
      monthCompensationAccural: 1038791.54,
      monthRepaymentAccural: 1038791.54,
      monthCompensationAccuralLeft: 0,
      totalBalance: 58635749.87,
    },
    {
      date: '2023-07-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3753271.59,
      monthMainDebtBalance: 54882478.28,
      monthCompensationAccural: 977262.5,
      monthRepaymentAccural: 977262.5,
      monthCompensationAccuralLeft: 0,
      totalBalance: 54882478.28,
    },
    {
      date: '2023-08-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3815826.12,
      monthMainDebtBalance: 51066652.16,
      monthCompensationAccural: 914707.97,
      monthRepaymentAccural: 914707.97,
      monthCompensationAccuralLeft: 0,
      totalBalance: 51066652.16,
    },
    {
      date: '2023-09-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3879423.22,
      monthMainDebtBalance: 47187228.94,
      monthCompensationAccural: 851110.87,
      monthRepaymentAccural: 851110.87,
      monthCompensationAccuralLeft: 0,
      totalBalance: 47187228.94,
    },
    {
      date: '2023-10-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 3944080.27,
      monthMainDebtBalance: 43243148.67,
      monthCompensationAccural: 786453.82,
      monthRepaymentAccural: 786453.82,
      monthCompensationAccuralLeft: 0,
      totalBalance: 43243148.67,
    },
    {
      date: '2023-11-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4009814.95,
      monthMainDebtBalance: 39233333.72,
      monthCompensationAccural: 720719.14,
      monthRepaymentAccural: 720719.14,
      monthCompensationAccuralLeft: 0,
      totalBalance: 39233333.72,
    },
    {
      date: '2023-12-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4076645.19,
      monthMainDebtBalance: 35156688.53,
      monthCompensationAccural: 653888.9,
      monthRepaymentAccural: 653888.9,
      monthCompensationAccuralLeft: 0,
      totalBalance: 35156688.53,
    },
    {
      date: '2024-01-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4144589.28,
      monthMainDebtBalance: 31012099.25,
      monthCompensationAccural: 585944.81,
      monthRepaymentAccural: 585944.81,
      monthCompensationAccuralLeft: 0,
      totalBalance: 31012099.25,
    },
    {
      date: '2024-02-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4213665.77,
      monthMainDebtBalance: 26798433.48,
      monthCompensationAccural: 516868.32,
      monthRepaymentAccural: 516868.32,
      monthCompensationAccuralLeft: 0,
      totalBalance: 26798433.48,
    },
    {
      date: '2024-03-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4283893.53,
      monthMainDebtBalance: 22514539.95,
      monthCompensationAccural: 446640.56,
      monthRepaymentAccural: 446640.56,
      monthCompensationAccuralLeft: 0,
      totalBalance: 22514539.95,
    },
    {
      date: '2024-04-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4355291.76,
      monthMainDebtBalance: 18159248.19,
      monthCompensationAccural: 375242.33,
      monthRepaymentAccural: 375242.33,
      monthCompensationAccuralLeft: 0,
      totalBalance: 18159248.19,
    },
    {
      date: '2024-05-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4427879.95,
      monthMainDebtBalance: 13731368.24,
      monthCompensationAccural: 302654.14,
      monthRepaymentAccural: 302654.14,
      monthCompensationAccuralLeft: 0,
      totalBalance: 13731368.24,
    },
    {
      date: '2024-06-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4501677.95,
      monthMainDebtBalance: 9229690.29,
      monthCompensationAccural: 228856.14,
      monthRepaymentAccural: 228856.14,
      monthCompensationAccuralLeft: 0,
      totalBalance: 9229690.29,
    },
    {
      date: '2024-07-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.09,
      monthMainDebtRepayment: 4576705.92,
      monthMainDebtBalance: 4652984.37,
      monthCompensationAccural: 153828.17,
      monthRepaymentAccural: 153828.17,
      monthCompensationAccuralLeft: 0,
      totalBalance: 4652984.37,
    },
    {
      date: '2024-08-15T00:00:00.000Z',
      receiptAmount: 0,
      monthAmountPayment: 4730534.11,
      monthMainDebtRepayment: 4652984.35,
      monthMainDebtBalance: 0,
      monthCompensationAccural: 77549.74,
      monthRepaymentAccural: 77549.74,
      monthCompensationAccuralLeft: 0,
      totalBalance: 0,
    },
  ],
  loanAmount: 79847000,
  loanAggreementAmount: 80000000,
  loanPercent: 20,
  dealCost: 51000,
  loanType: 'RECEIVED',
  interestAccrualMethod: 'M30_360',
  typeOfPayment: 'ANNUITY',
  aggreementNumber: 'N855596',
  aggreementDate: '2022-12-15',
  counterparty: 'testCounter',
  currency: 'KZT',
  dateOfIssueingLoan: '2022-12-15',
  monthCount: 20,
  transferHolidays: false,
  capitalization: false,
  moderatorType: 'standart',
  paymentPeriod: '',
  paymentDay: 0,
  paymentPeriodOfDebt: '',
  lastDateOfLoan: '',
  ear: 20.07829044586827,
  title: 'Займ № N855596 от 12/15/2022 на сумму 80000000 KZT testCounter',
};

export default function PaymentsListTable(props: any) {
  let { tableHeaders, tableData, tableType } = props;
  const theme = useTheme();

  const [headers, setHeaders] = useState<any>();
  const [rowLimit, setRowLimit] = useState(5);
  const [tabData, setTabData] = useState([] as any);

  useEffect(() => {
    LoanBus.getEAR(testObject)
      .then((res: any) => {
        const { grafic, ...rest } = res.data;
        setTabData(grafic);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    let tableHeader = tableHead?.filter((h) => h.type == tableType);

    setHeaders(tableHeader?.[0]?.heading);
  }, [tableHeaders]);

  return (
    <React.Fragment>
      <TableContainer sx={{ mt: 5, mb: 5 }}>
        <Table
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '3px 0',
          }}
        >
          <TableRow>
            {headers?.map((h: any, index: any) => (
              <TableCell
                key={index}
                align="center"
                style={{
                  background: index != 2 ? theme.palette.grey[100] : '#00AB5514',
                  borderRadius: 0,
                }}
                sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
              >
                <Typography variant="subtitle1" color={h?.child ? 'black' : 'gray'} fontSize={14}>
                  {h?.value}
                </Typography>
                {h?.child && (
                  <TableRow key={index}>
                    {h?.child?.map((i: any) => (
                      <TableCell key={index}>
                        <Typography fontWeight={300} color="gray" fontSize={14}>
                          {i?.value}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableCell>
            ))}
          </TableRow>

          <TableBody>
            {tabData?.slice(0, rowLimit)?.map((row: any, index: any) => (
              <TableRow key={index}>
                <TableCell
                  //   align="center"
                  style={{ background: theme.palette.grey[100], borderRadius: 0 }}
                  sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
                >
                  {moment(row?.date).format('MM/DD/YYYY')}
                </TableCell>
                <TableCell
                  key={index}
                  align="center"
                  style={{ background: theme.palette.grey[100], borderRadius: 0 }}
                  sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
                >
                  <Grid container justifyContent={'space-between'}>
                    <TableCell align="left" sx={{ p: 0 }}>
                      32423
                    </TableCell>
                    <TableCell align="right" sx={{ p: 0 }}>
                      2131
                    </TableCell>
                  </Grid>
                </TableCell>
                <TableCell
                  key={index}
                  align="center"
                  style={{
                    background: '#00AB5514',
                    borderRadius: 0,
                  }}
                  sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
                >
                  <Grid container justifyContent={'space-between'}>
                    <TableCell sx={{ p: 0 }} align="center">
                      32423
                    </TableCell>
                    <TableCell sx={{ p: 0 }} align="center">
                      2131
                    </TableCell>
                  </Grid>
                </TableCell>
                <TableCell
                  key={index}
                  align="center"
                  sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
                  style={{ background: theme.palette.grey[100], borderRadius: 0 }}
                >
                  <Grid container justifyContent={'space-between'}>
                    <TableCell sx={{ p: 0 }} align="center">
                      32423
                    </TableCell>
                    <TableCell sx={{ p: 0 }} align="center">
                      2131
                    </TableCell>
                    <TableCell sx={{ p: 0 }} align="center">
                      2131
                    </TableCell>
                  </Grid>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ background: theme.palette.grey[100], borderRadius: 0 }}
                  sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
                >
                  {/* {edit ? (
                    <TextField
                      type={'number'}
                      key={index}
                      onChange={(e) => {
                        handleChangePayments(parseFloat(e.target.value + ''), row?.date + '');
                      }}
                      style={{ border: 'none' }}
                      value={row.monthAmountPayment}
                    />
                  ) : (
                    row.monthAmountPayment
                  )} */}
                </TableCell>
                {/* <TableCell
                          align="right"
                          style={{ background: 'rgba(24, 144, 255, 0.08)',, borderRadius: 0 }}
                        ></TableCell> */}
              </TableRow>
            ))}

            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="right" sx={{ cursor: 'pointer' }}>
                <Grid container justifyContent={'space-between'}>
                  <Typography color="#1890FF" fontSize={13} fontWeight={700}>
                    Развернуть
                  </Typography>
                  <Grid item sx={{ ml: '5px' }}>
                    <img src="/icons/icon-top.svg" />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
