import axios from 'axios';
const accessToken = localStorage.getItem('accessToken');

export const HTTP = axios.create({
  baseURL: 'https://devapi.steppetech.com/',
  headers: {
    Authorization: 'Bearer ' + accessToken,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
