import {HTTP} from './http-common'

class LoanBus { 

    static getPaymentsGraphic(params: any) {
        return HTTP.post('/calculations/get-payment-grafic', params )
    }
    
    static getEAR(params: any) {
        return HTTP.post('/calculations/ear-grafic', params )
    }

    static saveLoan(customerId : string , params : any){
        return HTTP.post(`/loans?customerId=${customerId}` , params)
    }
    
}

export default LoanBus