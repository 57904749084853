// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Grid } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
import useOffSetTop from '../../../hooks/useOffSetTop';
import { useLocation } from 'react-router-dom';
import menuDashboard from '../../main/MenuUnauth';

// config
import {
  DASHBOARD_NAVBAR_WIDTH,
  DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
  DASHBOARD_HEADER_MOBILE,
  DASHBOARD_HEADER_DESKTOP,
} from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import BalancePopover from './BalancePopover';
import NotificationsPopover from './NotificationsPopover';
import MenuDesktop from '../../main/MenuDesktop';
import MenuMobile from '../../main/MenuMobile';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean | undefined;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<RootStyleProps>(({ isCollapse, theme }) => ({
  boxShadow: 'none',
  ...cssStyles(theme).bgBlur(),
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('xl')]: {
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${DASHBOARD_NAVBAR_COLLAPSE_WIDTH}px)`,
    }),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(0, 5),
    minHeight: DASHBOARD_HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardHeader({ onOpenSidebar }: Props) {
  const { isCollapse } = useCollapseDrawer();
  const { isAuthenticated } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  console.log('isAuthenticated', isAuthenticated);

  return (
    <RootStyle isCollapse={isCollapse}>
      <ToolbarStyle>
        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}

        {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={menuDashboard} />}
        {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={menuDashboard} />}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          {isAuthenticated && (
            <>
              <NotificationsPopover />
              <BalancePopover />
              <AccountPopover />
            </>
          )}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
