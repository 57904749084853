import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Label from 'src/components/Label';
import moment from 'moment';

export default function LoanHeader(props: any) {
  const {
    loanStatus,
    title,
    loanType,
    loanPercent,
    aggrNumber,
    counterparty,
    loanAmount,
    dealCost,
    aggrDate,
    currency,
    ear,
  } = props;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box sx={{ textAlign: { sm: 'left' } }}>
            {loanStatus != 'newLoan' && (
              <Label color="success" sx={{ textTransform: 'uppercase', mb: 1 }}>
                Действующий
              </Label>
            )}
            <Typography variant="h6">{loanStatus == 'newLoan' ? title : ''}</Typography>
          </Box>
        </Grid>
        <Grid item md={12}  sx={{ mb: 5 }}>
              <Typography variant='body1'><b>Займ №</b>1231324 <b>от</b> 08.15.22 <b>на сумму</b> 1,000,000 <b>тенге ТОО</b> "Строймонтаж"</Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            Информация
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" xs={12}>
          <Grid item xs={3}>
            <Typography variant="body2" sx={{ pt: 1 }}>
              Тип займа
            </Typography>
            <Typography variant="body2" sx={{ pt: 1 }}>
              {loanType == 'GIVEN' ? 'Выданный' : 'Полученный'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" sx={{ pt: 1 }}>
              Ставка займа
            </Typography>
            <Typography variant="body2" sx={{ pt: 1 }}>
              {loanPercent} %
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" sx={{ pt: 1 }}>
              Номер соглашения
            </Typography>
            <Typography variant="body2" sx={{ pt: 1 }}>
              {aggrNumber}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" sx={{ pt: 1 }}>
              Контрагент
            </Typography>
            <Typography variant="body2" sx={{ pt: 1 }}>
              {counterparty}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" sx={{ mt: 5 }}>
        <Grid item xs={12} sm={3}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            Общая сумма по соглашению
          </Typography>
          <Typography variant="body2">
            {loanAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            {currency == 'KZT' ? ' ₸' : ` ${currency}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            Затраты по сделке
          </Typography>
          <Typography variant="body2">
            {dealCost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₸
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
            Дата соглашения
          </Typography>
          <Typography variant="body2">{moment(aggrDate).format('MM/DD/YYYY')}</Typography>
        </Grid>
        <Grid item sm={3}>
          {ear && (
            <>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                EIR
              </Typography>
              <Typography variant="body2">{ear}%</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
