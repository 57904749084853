import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { EventInput } from '@fullcalendar/common';
// utils
import axios from '../../utils/axios';
// @types
import { Notification } from '../../@types/notification';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: Notification = { 
  error: "", 
  success: "",
  show: false
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // Message success
    messageSuccess(state, action) {
        state.show = true;
        state.success = action.payload;
    },

    // message error
    messageError(state, action) {
      state.show = true;
      state.error = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { messageSuccess, messageError } = slice.actions;
