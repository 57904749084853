import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    Loan : {},
    loanTitle :"",
    loanCreated: "",
    LoanGraph : {},
}

export const loanSlice = createSlice(
    {
        name : 'loan',
        initialState,
        reducers:{
            updateLoanForm(state, action)
            {
                state.Loan = action.payload
            },
            updateLoanTitle(state , action){
                state.loanTitle = action.payload
            },
            updateLoanGraph(state , action){
                state.LoanGraph = action.payload
            },
            updateLoanDate(state , action){
                state.loanCreated = action.payload
            }
        }
    }
)
export const {updateLoanForm , updateLoanTitle, updateLoanGraph , updateLoanDate} = loanSlice.actions
export default loanSlice.reducer