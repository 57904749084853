// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { Children } from 'react';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  loans: getIcon('ic_analytics'),
  report: getIcon('ic_report'),
  reports: getIcon('ic_blog'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  loanInfo: getIcon('ic_loanInfo'),
  earIcon: getIcon('ic_earIcon'),
  calc: getIcon('ic_chart'),
  bu: getIcon('BU'),
};

const loanConfig = [
  {
    subheader: 'График займа',
    items: [
      {
        title: 'Общие сведения',
        path: PATH_DASHBOARD.general.loanInfo,
        icon: ICONS.loanInfo,
        isPro: false,
      },
      {
        title: 'Расчет ЭПС',
        path: PATH_DASHBOARD.general.loanEar,
        icon: ICONS.earIcon,
        isPro: false,
      },
      {
        title: 'Учет',
        path: PATH_DASHBOARD.general.loanBuInfo,
        icon: ICONS.bu,
        children: [
          { title: 'История платежей', path: PATH_DASHBOARD.loanBU.paymentHistory },
          { title: 'Параметры первоначального признания  ', path: PATH_DASHBOARD.general.loans },
          { title: 'Модификация', path: PATH_DASHBOARD.general.loans },
          { title: 'Прекращение признания', path: PATH_DASHBOARD.general.loans },
        ],
      },
      {
        title: 'Отчеты',
        path: PATH_DASHBOARD.general.analytics,
        icon: ICONS.report,
        isPro: true,
        children: [
          { title: 'График займа по МСФО', path: PATH_DASHBOARD.general.loans },
          { title: 'Проводки', path: PATH_DASHBOARD.general.loans },
          { title: 'Раскрытия', path: PATH_DASHBOARD.general.loans },
        ],
      },
      {
        title: 'Модификация',
        path: PATH_DASHBOARD.general.analytics,
        icon: ICONS.earIcon,
        isPro: true,
      },
      {
        title: 'Прекращение признания',
        path: PATH_DASHBOARD.general.analytics,
        icon: ICONS.earIcon,
        isPro: false,
      },
    ],
  },
];

export default loanConfig;
