import { useRef, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Typography, ListItemText, ListItemButton, ListItemAvatar } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _contacts } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';
import { useNavigate } from 'react-router';
import { PATH_CABINET } from 'src/routes/paths';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;
const PADDING_ITEM = 2.5;

// ----------------------------------------------------------------------

export default function BalancePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let role = localStorage.getItem('role');

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        size="large"
        color={'default'}
        disableRipple
        disableFocusRipple
        onClick={() =>
          navigate(
            role == 'USER'
              ? PATH_CABINET.user.account
              : role == 'BUSINESS_USER'
              ? PATH_CABINET.employee.profile
              : role == 'BUSINESS_OWNER'
              ? PATH_CABINET.company.account
              : '',
            { state: 'payments' }
          )
        }
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
          // '&: hover': {
          //   background: 'transparent',
          //   fontSize: 14,
          // },
          // '&: focus': {
          //   background: 'transparent',
          //   fontSize: 14,
          // },
          fontSize: 14,
        }}
      >
        <Iconify icon={'ion:wallet'} width={20} height={20} sx={{ mr: 1 }} />
        500 000
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, p: 1 }}
      >
        <Typography variant="h6" sx={{ p: PADDING_ITEM }}>
          Платежи
          {/* <Typography component="span">({_contacts.length})</Typography> */}
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 8 }}>
          {_contacts.map((contact) => (
            <ListItemButton
              key={contact.id}
              sx={{ px: PADDING_ITEM, height: ITEM_HEIGHT, borderRadius: 1 }}
            >
              <ListItemAvatar sx={{ position: 'relative' }}>
                <Avatar src={contact.avatar} />
                <BadgeStatus
                  status={contact.status}
                  sx={{ position: 'absolute', right: 1, bottom: 1 }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                secondaryTypographyProps={{ typography: 'caption' }}
                primary={contact.name}
                secondary={contact.status === 'offline' && fToNow(contact.lastActivity)}
              />
            </ListItemButton>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
