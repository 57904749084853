import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerId: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateCustomerId(state, action) {
      state.customerId = action.payload;
    },
  },
});

export const { updateCustomerId } = userSlice.actions;
export default userSlice.reducer;
