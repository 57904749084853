import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  List,
  ListItem,
  Button,
  Snackbar,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@mui/material';
import Page from 'src/components/Page';
import { Breadcrumbs } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import LoanHeader from 'src/components/loanComponents/LoanHeader';
import Scrollbar from 'src/components/Scrollbar';
import { Table } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import GraphicTable from 'src/components/GraphicTable';
import PaymentsListTable from 'src/components/loan/PaymentsListTable';
import { Link as RouterLink } from 'react-router-dom';


const PaymentsList = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { loanTitle, Loan } = useSelector((state) => state.loan);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    isEmpty(Loan) && enqueueSnackbar('График займа пуст', { variant: 'error' });
  }, [Loan]);

  return (
    <Page title="История платежей">
      <Container maxWidth={'xl'}>
        <Grid container justifyContent="flex-start" direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h4">История платежей</Typography>
          </Grid>
          <Grid item>
            <Breadcrumbs
              aria-label="breadcrumbs"
              separator={
                <Box
                  component="span"
                  sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }}
                />
              }
            >
              {isAuthenticated ? (
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<ArrowBack />}
                  // disabled={edit}
                  onClick={() => navigate('/dashboard/loans')}
                >
                  Все займы
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<ArrowBack />}
                  // disabled={edit}
                  onClick={() => navigate('/dashboard/loan/new')}
                >
                  Новый займ
                </Button>
              )}

              <Link to={'/'} style={{ textDecoration: 'none', color: 'gray' }}>
                <Typography variant="body2">{loanTitle}</Typography>
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" sx={{ mt: 3, mb: 3 }}>
          <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
            <Grid container justifyContent="flex-start">
              <IconButton style={{ height: 40, width: 40, marginRight: 8 }}>
                <Iconify icon={'ant-design:download-outlined'} />
              </IconButton>
              <IconButton style={{ height: 40, width: 40, margin: '0px 8px' }}>
                <Iconify icon={'fluent:print-28-filled'} />
              </IconButton>
              <IconButton style={{ height: 40, width: 40, margin: '0px 8px' }}>
                <Iconify icon={'fluent:send-28-filled'} />
              </IconButton>
            </Grid>
          </Grid>
          
          <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
            <Grid container justifyContent="flex-end">
              <Grid item xl={2.5} lg={3}>
                {/* {detailsType != 'newLoan' && ( */}
                <Box sx={{ textAlign: { sm: 'left', float: 'right' } }}>
                  {/* {_invoice.status == 'Оплачено' ? (
                        <Label
                          color="success"
                          variant="outlined"
                          sx={{ textTransform: 'uppercase', mb: 1 }}
                        >
                          {_invoice.status}
                        </Label>
                      ) : (
                        <Label
                          color="error"
                          variant="outlined"
                          sx={{ textTransform: 'uppercase', mb: 1 }}
                        >
                          Неоплачен
                        </Label>
                      )} */}
                  <Label
                    color="error"
                    variant="outlined"
                    sx={{ textTransform: 'uppercase', mb: 1 }}
                  >
                    Неоплачен
                  </Label>
                </Box>
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card sx={{ pt: 5, px: 5 }}>
          {/* {Loan && ( */}
          <LoanHeader
            // loanStatus={detailsType}
            title={loanTitle}
            loanType={'test'}
            loanPercent={50}
            aggrNumber={'Loan?.aggreementNumber'}
            counterparty={'Loan?.counterparty'}
            loanAmount={'Loan?.loanAggreementAmount'}
            dealCost={'Loan?.dealCost'}
            aggrDate={'Loan?.aggreementDate'}
            currency={'Loan?.currency'}
          />
          <Scrollbar>
            <PaymentsListTable
              // headers = {}
              //   tableData = {EPSDate}
              tableType={'graphic'}
            />
          </Scrollbar>
          <Grid container justifyContent="space-between">
          <Grid item md={10}>
          <Typography color={'gray'}>
            Система не смогла получить данные о погашении займа в разрезе основного долга и вознаграждений, поскольку ввод данных был осуществлен вручную/откорректирован либо загрузкой данных из Excel. 
            <br /> <br />
            Для получения корректной информации о корректирующих проводках сначала заполните столбец 
            погашения основного долга, чтобы итоговый остаток по основному долгу был равен нулю, и нажмите "Далее"
          </Typography>
          </Grid>
           <Grid item md={1}>
            <Button 
                color="secondary" 
                style={{backgroundColor: "#DFE3E8", color: "#333333"}} 
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.loanBU.accountingSelect}
                >Далее</Button>
            </Grid>
          </Grid>
          <Grid container alignContent={"flex-end"} flexDirection="column" style={{paddingBottom: "30px"}}>
              <Grid item md={3}>
                  <Typography variant='subtitle2' textAlign={"right"}>Есть вопросы?</Typography>
                  <Typography>support@abcapp.com</Typography>
              </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default PaymentsList;
