import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import blogReducer from './slices/blog';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import notification from './slices/notification';
import loanReducer from './slices/loan';
import userReduces from './slices/user';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};
const loanPersistConfig = {
  key: 'loan',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['Loan', 'loanTitle', 'LoanGraph', 'loanCreated'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['customerId'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  blog: blogReducer,
  calendar: calendarReducer,
  product: persistReducer(productPersistConfig, productReducer),
  notification: notification,
  loan: persistReducer(loanPersistConfig, loanReducer),
  user: persistReducer(userPersistConfig, userReduces),
});

export { rootPersistConfig, rootReducer };
