import { noCase } from 'change-case';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils

// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import moment from 'moment';
// ----------------------------------------------------------------------

let notify = [
  {
    title: 'Выставлен счет на оплату', 
    description: 'Доступно в кабинете', 
    type: 'order_placed',
    createdAt: '05.03.2000',
    isUnRead: true,
  },
];

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(notify); 

  const totalUnRead = notifications?.filter((item) => item.isUnRead === true).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  }; 

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Уведомления</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              У вас 15 новых уведомлений
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Новые
              </ListSubheader>
            }
          >
            {/* {notifications?.map((notification, index) => (
              <NotificationItem key={index} notification={notification} />
            ))} */}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Старые
              </ListSubheader>
            }
          >
            {/* {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))} */}
          </List>
        </Scrollbar>

        <Divider />
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  // id: string;
  title?: string | "";
  description?: string | "";
  // avatar: string | null;
  type?: string | "";
  createdAt?: string | number | Date;
  isUnRead?: boolean;
};

function NotificationItem({ notification }: { notification: NotificationItemProps }) {
  const { title } = renderContent(notification);
  // const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} /> 
            {moment(notification?.createdAt).format('DD.MM.yyyy')}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const title = (
    <Typography variant="subtitle2">
      {notification?.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; <br /> {notification?.description }
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
      //   />
      // ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
      //   />
      // ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
      //   />
      // ),
      title,
    };
  }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  return {
    // avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
