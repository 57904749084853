import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Grid } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { MAIN_HEADER_DESKTOP, MAIN_HEADER_MOBILE } from '../../config';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import menuUnauth from './MenuUnauth';
import { Link as RouterLink } from 'react-router-dom';

import LanguagePopover from '../dashboard/header/LanguagePopover';
import useAuth from 'src/hooks/useAuth';
import menuAuth from './MenuAuth copy';
import AccountPopover from '../dashboard/header/AccountPopover';
import NotificationsPopover from '../dashboard/header/NotificationsPopover';
import { PATH_AUTH } from 'src/routes/paths';
import BalancePopover from '../dashboard/header/BalancePopover';

// ----------------------------------------------------------------------

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(100);
  const location = useLocation();

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';
  const { isAuthenticated, user } = useAuth();

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: MAIN_HEADER_MOBILE,
    background:
      location?.pathname?.includes('dashboard') || location?.pathname?.includes('cabinet')
        ? 'rgba(255, 255, 255, 1)'
        : '',
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      height: MAIN_HEADER_DESKTOP,
    },
  }));

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: MAIN_HEADER_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          maxWidth={'lg'}
        >
          <Logo sx={{ mr: 5 }} />

          {isDesktop && (
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              navConfig={isAuthenticated ? menuAuth : menuUnauth}
            />
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Grid item>
            <LanguagePopover />
          </Grid>
          {!isAuthenticated && (
            <>
              <Grid item xs={0.8}>
                <Button
                  variant="outlined"
                  rel="noopener"
                  component={RouterLink}
                  to={PATH_AUTH.login}
                >
                  Вход
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  rel="noopener"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                >
                  Регистрация
                </Button>
              </Grid>
            </>
          )}
          {isAuthenticated && window.location.pathname.includes('cabinet') && (
            <>
              <NotificationsPopover />
              <BalancePopover />
              <AccountPopover />
            </>
          )}

          {!isDesktop && (
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={isAuthenticated ? menuAuth : menuUnauth}
            />
          )}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
