import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';
import { PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------


const isValidToken = (accessToken: string) => {
  if (!accessToken) { 
    return false;
  }  
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000; 
  if(decoded.exp < currentTime){
    localStorage.removeItem("accessToken");
    return false;
  }
  return decoded.exp > currentTime;
  // return true;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };


const HandleLogout = async () => {
  const { logout } = useAuth();
  localStorage.removeItem('accessToken');
  try {
    await logout?.();
    
      localStorage.setItem('accessToken', '');
      // navigate(PATH_PAGE.home);
    
  } catch (error) {
    console.error(error);
    // enqueueSnackbar('Unable to logout', { variant: 'error' });
  }
};

const setSession = (accessToken: string | null) => {
  console.log("ya tut111u");
 
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    HandleLogout();    
    delete axios.defaults.headers.common.Authorization;

  }
};

export { isValidToken, setSession, verify, sign };
