import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import CompanyCreateEmployee from '../pages/cabinet/company/CompanyCreateEmployee';
import PaymentsList from 'src/pages/dashboard/loans/payment-history/PaymentsList';
import Accounting from 'src/pages/dashboard/loans/accounting/Accounting';
import AccountingSelect from 'src/pages/dashboard/loans/accounting/AccountingSelect';
import AccountingEdit from 'src/pages/dashboard/loans/accounting/AccountingEdit';
import AccountingAddDate from 'src/pages/dashboard/loans/accounting/AccountingAddDate';
// import LoanCreate from '../pages/dashboard/loans/LoanCreate';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'cabinet',
      // element: <Navigate to="/cabinet/user/profile" replace />,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      // index: true,

      children: [
        {
          path: 'user',
          children: [
            {
              element: <UserProfile />,
              index: true,
            },
            { path: 'profile', element: <UserProfile /> },
            { path: 'settings', element: <UserSettings /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'company',
          children: [
            {
              element: (
                <AuthGuard>
                  <Navigate to="/cabinet/company/profile" replace />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: 'profile',
              element: <CompanyProfile />,
            },
            { path: 'list', element: <CompanyList /> },
            { path: 'settings', element: <CompanySettings /> },
            { path: 'employee-edit', element: <CompanyCreate /> },
            { path: ':name/edit', element: <CompanyCreate /> },
            { path: 'account', element: <CompanyAccount /> },
            { path: 'view-employee-profile', element: <CompanyViewEmployeeProfile /> },
          ],
        },
        {
          path: 'employee',
          children: [
            {
              element: (
                <AuthGuard>
                  <Navigate to="/cabinet/employee/profile" replace />{' '}
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: 'profile',
              element: <EmployeeProfile />,
            },
            { path: 'cards', element: <EmployeeCards /> },
            { path: 'list', element: <EmployeeList /> },
            { path: 'new', element: <EmployeeCreate /> },
            { path: ':name/edit', element: <EmployeeCreate /> },
            { path: 'account', element: <EmployeeAccount /> },
          ],
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/loans" replace />, index: true },
        { path: 'loans', element: <GeneralApp /> },
        { path: 'loan/new', element: <LoanCreate /> },
        { path: 'loan-details/:id', element: <LoanDetails /> },
        { path: 'loan-details', element: <LoanDetails /> },
        { path: 'loan-ear', element: <LoanEar /> },
        { path: 'loan-bu-info', element: <LoanBUInfo /> },
        { path: 'accounting', element: <Accounting /> },
        { path: 'Accounting-select', element: <AccountingSelect /> },
        { path: 'Accounting-edit', element: <AccountingEdit /> },
        { path: 'Accounting-add-date', element: <AccountingAddDate /> },
        { path: 'loan-bu-payments-history', element: <PaymentsList /> },

        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        { path: 'calendar', element: <Calendar /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'verify', element: <VerifyByEmail /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const VerifyByEmail = Loadable(lazy(() => import('../pages/auth/VerifyByEmail')));

// Loans

const LoanCreate = Loadable(lazy(() => import('../pages/dashboard/loans/LoanCreate')));
const LoanDetails = Loadable(lazy(() => import('../pages/dashboard/loans/LoanDetails')));
const LoanEar = Loadable(lazy(() => import('../pages/dashboard/loans/LoanEar')));
const LoanBUInfo = Loadable(
  lazy(() => import('../pages/dashboard/loans/payment-history/LoanBUInfo'))
);

// personal cabinet
const UserProfile = Loadable(lazy(() => import('../pages/cabinet/user/UserProfile')));
const UserSettings = Loadable(lazy(() => import('../pages/cabinet/user/UserSettings')));
const UserCards = Loadable(lazy(() => import('../pages/cabinet/user/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/cabinet/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/cabinet/user/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/cabinet/user/UserCreate')));

// company cabinet
const CompanyProfile = Loadable(lazy(() => import('../pages/cabinet/company/CompanyProfile')));
const CompanySettings = Loadable(lazy(() => import('../pages/cabinet/company/CompanySettings')));
const CompanyList = Loadable(lazy(() => import('../pages/cabinet/company/CompanyList')));
const CompanyAccount = Loadable(lazy(() => import('../pages/cabinet/company/CompanyAccount')));
const CompanyCreate = Loadable(
  lazy(() => import('../pages/cabinet/company/CompanyCreateEmployee'))
);
const CompanyViewEmployeeProfile = Loadable(
  lazy(() => import('../pages/cabinet/company/CompanyViewEmployeeProfile'))
);

// employee cabinet
const EmployeeProfile = Loadable(lazy(() => import('../pages/cabinet/employee/EmployeeProfile')));
const EmployeeSettings = Loadable(lazy(() => import('../pages/cabinet/employee/EmployeeSettings')));
const EmployeeCards = Loadable(lazy(() => import('../pages/cabinet/employee/EmployeeCards')));
const EmployeeList = Loadable(lazy(() => import('../pages/cabinet/employee/EmployeeList')));
const EmployeeAccount = Loadable(lazy(() => import('../pages/cabinet/employee/EmployeeAccount')));
const EmployeeCreate = Loadable(lazy(() => import('../pages/cabinet/employee/EmployeeCreate')));

// Dashboard

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
