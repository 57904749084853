import jwtDecode from 'jwt-decode';
import path from 'path';
import { useState, ReactNode, useEffect, useReducer } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Register from 'src/pages/auth/Register';
import { dispatch } from 'src/redux/store';

// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [authMode, setAuthMode] = useState(isAuthenticated);

  let authProtected = [{ path: 'loan/new' }, { path: 'loan-details' }, { path: 'loan-ear' }];

  useEffect(() => {
    let accessToken = localStorage.getItem('accessToken'); 

    if (accessToken && accessToken != '') {

      setAuthMode(true);
    } else {
      setAuthMode(false);
    }
  }, [isAuthenticated, pathname, localStorage]);

  if (pathname && authProtected?.filter((l) => pathname?.includes(l.path))?.length == 0) {
    if (!authMode) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return <Login />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
