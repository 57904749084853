import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import jwtDecode from 'jwt-decode';
import Auth from '../models/auth';
import axios from 'axios';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken && accessToken != '') {
        if (isValidToken(accessToken)) {
          setSession(accessToken);

          const decSup = jwtDecode<{ sub: string }>(accessToken);
          let sub = decSup?.sub;
          localStorage.setItem('user_id', sub);

          // const response = await Auth.profile(sub);
          const res = await axios.get(`https://devapi.steppetech.com/users/${sub}`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          console.log("hello user: ", res)

          const user = res?.data;
          user && localStorage.setItem('role', user?.globalRole);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    let params = {
      email,
      password,
    };
    const response = await Auth.login(params);
    const { accessToken } = response.data;

    setSession(accessToken);

    const decSup = jwtDecode<{ sub: string }>(accessToken);
    let sub = decSup?.sub;

    let userData = null;
    let user = null;
    // if (localStorage.getItem('accessToken')) {
    //   userData = await Auth.profile(sub);
    //   user = userData.data;
    //   user && localStorage.setItem('role', user?.globalRole);
    // }

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    let err:any = null;
    // setTimeout(() => { 
    //   if (localStorage.getItem('accessToken')) {
    //   Auth.profileGet(
    //     (data: any) => (user = data.data),
    //     (errors: any) => (err = errors),
    //     sub
    //   );
    //   }
    // })
    const res = await axios.get(`https://devapi.steppetech.com/users/${sub}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    console.log("hello user: ", res)
    user = res.data
    if (user) {
      dispatch({
        type: Types.Register,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
  };

  const register = async (params: any) => {
    const response = await Auth.register(params);
    const { accessToken } = response.data;

    localStorage.setItem('accessToken', accessToken);
    setSession(accessToken);
    const decSup = jwtDecode<{ sub: string }>(accessToken);
    let sub = decSup?.sub;

    let userData = null;
    let user = null;
    let err:any = null;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // setTimeout(() => {
    //    if (localStorage.getItem('accessToken')) {
    //   //   userData = await Auth.profile(sub);
    //   //   user = userData.data;
    //   //   user && localStorage.setItem('role', user?.globalRole);
    //   Auth.profileGet(
    //     (data: any) => (user = data.data),
    //     (errors: any) => (err = errors),
    //     sub
    //   );
    //   }
      
    // })
    const res = await axios.get(`https://devapi.steppetech.com/users/${sub}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    console.log("hello user: ", res)
    console.log("hello user: ", res)
    user = res.data;
    user && localStorage.setItem('role', user?.globalRole);
    
   

    if (user) {
      dispatch({
        type: Types.Register,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
