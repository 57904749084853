// routes
import { PATH_AUTH, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuAuth = [
  {
    title: 'Модули',
    path: '/moduls',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Финансовые инструменты',
        items: [{ title: 'Займы', path: PATH_DASHBOARD.general.loans }],
      },
    ],
  },
];

export default menuAuth;
