import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography, Stack, Avatar } from '@mui/material';
// routes
import { PATH_CABINET, PATH_PAGE } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import SwapVert from '@mui/icons-material/SwapVertSharp';
import { deepOrange, deepPurple, amber, green } from '@mui/material/colors';

// ----------------------------------------------------------------------

let role = window.localStorage.getItem('role');

const MENU_OPTIONS = [
  {
    label: 'Профиль',
    linkTo:
      role === 'USER'
        ? PATH_CABINET.user.profile
        : role === 'BUSINESS_USER'
        ? PATH_CABINET.employee.profile
        : role === 'BUSINESS_OWNER'
        ? PATH_CABINET.company.profile
        : '',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([] as { label?: string; linkTo?: string });
  let role = localStorage.getItem('role');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    try {
      if (isMountedRef.current) {
        localStorage.setItem('accessToken', '');
        handleClose();
        await logout();
        navigate(PATH_PAGE.home);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          sx={{
            width: 35,
            height: 35,
            fontSize: 20,
            textTransform: 'uppercase',
            margin: '0px auto',
            bgcolor: deepPurple[400],
            color: '#fff',
          }}
        >
          {user?.firstName?.[0] + user?.lastName?.[0]}
        </Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
        {role == 'BUSINESS_USER' ||
          (role == 'BUSINESS_OWNER' && (
            <>
              <Divider orientation="horizontal" flexItem>
                <SwapVert color="disabled" />
              </Divider>
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle1" noWrap color="blue">
                  {user?.industry || 'Comp'}
                </Typography>
              </Box>
            </>
          ))}
        <Stack spacing={0.5} sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <Divider />

        <MenuItem
          onClick={handleLogout}
          sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1, m: 1 }}
        >
          Выход
        </MenuItem>
      </MenuPopover>
    </>
  );
}
