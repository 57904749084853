import { AnyAaaaRecord } from 'dns'
import {HTTP} from './http-common'

class Auth {
    // static register(then: any, catcher: any, params: any) {
    //     return HTTP.post('/register', params)
    //         .then(({data: any}) => then(data))
    //         .catch((errors) => catcher(errors))
    // }

    static register(params: any) {
        return HTTP.post('/auth/register', params )
    }

    
    static login(params: any) {
        return HTTP.post('/auth/login', params )
    }

    static profile(id: any) {
        return HTTP.get(`users/${id}`)
    }

    static profileGet(then: any, catcher: any, id:any) {
        return HTTP.get(`users/${id}`)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors))
    }

    static changePassword(then: any, catcher: any, params: any) { 
        return HTTP.post(`/users/update-password`, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors))
    }

    // static logout(then: any, catcher: any, params = null) {
    //     return HTTP.get('/logout', { params })
    //         .then(({data}) => then(data))
    //         .catch((errors) => catcher(errors))
    // }
    // static editUser(then: any, catcher: any, params = null) {
    //     return HTTP.put('/user', params)
    //         .then(({data}) => then(data))
    //         .catch((errors) => catcher(errors))
    // }
    
    // static resetPassword(then: any, catcher: any, params = null) {
    //     return HTTP.post('/password/create', params)
    //         .then(({data}) => then(data))
    //         .catch((errors) => catcher(errors))
    // }
    // static upload(then: any, catcher: any, params = null) {
    //     return HTTP.post('/change-avatar', params)
    //         .then(({data}) => then(data))
    //         .catch((errors) => catcher(errors))
    // } 
}

export default Auth
